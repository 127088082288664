import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Components
import SEO from "../components/seo";
import Layout from "../layouts/index";

// Styled Components
const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ControlButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &.prev {
    left: 0px;
  }

  &.next {
    right: 0px;
  }

  svg {
    width: 40px;
    height: 40px;
    fill: #f58520; /* Color of the arrows */
  }
`;

const FigmaEmbed = () => {
    const iframeRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      // Handle incoming messages from the iframe
      const handleMessage = (event) => {
        if (event.origin === 'https://www.figma.com') {
          console.log('Message received from Figma:', event.data);
  
          if (event.data.type === 'INITIAL_LOAD') {
            setIsLoaded(true);
          }
        }
      };
  
      window.addEventListener('message', handleMessage);
  
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    }, []);
  
    // Send message to iframe to control navigation
    const sendMessageToIframe = (message) => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.contentWindow.postMessage(message, 'https://www.figma.com');
      }
    };
  
    const nextSlide = () => sendMessageToIframe({ type: 'NAVIGATE_FORWARD' });
    const prevSlide = () => sendMessageToIframe({ type: 'NAVIGATE_BACKWARD' });

  return (
    <Layout>
      <SEO 
        description="Explore the Privacy Policy of FFG. Digital, where your online security matters most. Learn how we safeguard your data and respect your privacy on our platform."
        title="Full Funnel Growth | Growth Marketing Agency" 
      />
      <div style={{ position: 'relative', margin: '0 auto', maxWidth: '1440px' }}>
      <IframeContainer>
        <iframe
          ref={iframeRef}
          src="https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/deck/F3pVrk9IYFfiB1lYx7H22s/POM-TECH-PROPOSAL?kind=deck&node-id=1-1027L&client-id=FDtti1jwMlMzuLhLErDcZi"
          allowFullScreen
          title="Figma Embed"
        />
      </IframeContainer>
      {isLoaded && (
        <>
          <ControlButton className="prev" onClick={prevSlide}>
            <svg viewBox="0 0 24 24">
              <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
            </svg>
          </ControlButton>
          <ControlButton className="next" onClick={nextSlide}>
            <svg viewBox="0 0 24 24">
              <path d="M8.59 16.59L13.17 12l-4.58-4.59L10 6l6 6-6 6z" />
            </svg>
          </ControlButton>
          {/* <RestartButton onClick={restartSlide}>Restart Prototype</RestartButton> */}
        </>
      )}
    </div>
    </Layout>
  );
};

export default FigmaEmbed;
